import React from "react";
import "./Home.scss";
import Hero from "../../Section/Hero/Hero";

import ScrollToAnchor from "./ScrollToAnchor";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Product from "../../Section/Product/Product";
import Facilities from "../../Section/Facilities/Facilities";
import Surrounding from "../../Section/Surrounding/Surrounding";

const Home = () => {
  return (
    <div className="home">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Promo />
      <Product />
      <Facilities />
      <Surrounding />
    </div>
  );
};

export default Home;
