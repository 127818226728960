import React from "react";
import "./Facilities.scss";
import clubhouse from "../../Assets/Facilities/facility.webp";
import joggingtrack from "../../Assets/Facilities/facility 2.webp";
import civiccenter from "../../Assets/Facilities/facility 3.webp";
import breeze from "../../Assets/Facilities/facility 4.webp";
import qbig from "../../Assets/Facilities/facility 5.webp";
import eka from "../../Assets/Facilities/facility 6.webp";
import shop from "../../Assets/Facilities/facility 7.webp";
import hotel from "../../Assets/Facilities/facility 8.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Facilities = () => {
  const slides = [
    { src: clubhouse, des: "Garden Path" },
    { src: joggingtrack, des: "Clubhouse" },
    { src: civiccenter, des: "Gymnasium" },
    { src: breeze, des: "Swimming Pool" },
    { src: qbig, des: "Children Playground" },
    { src: eka, des: "Jogging track" },
    { src: shop, des: "Gazebo" },
    { src: hotel, des: "3 on 3 Basketball Court" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="surroundingcontainer" id="fasilitas">
      <div className="title">LUXURIOUS FACILITIES</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Facilities;
