import "./Product.scss";
import CardProduct from "../../Component/Card/CardProduct.jsx";
import dataProduct from "./index.json";
import { ReactComponent as Divider } from "../../Assets/svg/divider.svg";
import { l7Images, l8Images, l9Images } from "../../Assets/Product/index.js";
const imageMap = {
  l7Images,
  l8Images,
  l9Images,
};
const Product = () => {
  return (
    <section className="section-produk" id="rumah">
      <div className="container">
        <Divider fill="#65312F" width={154} height={25} />
        <h2 className="title">OUR RESIDENTIALS</h2>
        <div className="container-card">
          {dataProduct.cardsResidentials.map((data, index) => (
            <CardProduct
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              details={data.details}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Product;
