import "./Surrounding.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconHospital } from "../../Assets/svg/hospital.svg";
import { ReactComponent as IconEducation } from "../../Assets/svg/education.svg";
import { ReactComponent as IconShopping } from "../../Assets/svg/shopping.svg";
import surroundingImages from "../../Assets/Surrounding/surroundingImages";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const pointData = {
    poin1: [
      "RS Mandaya Royal Hospital",
      "RSPI Puri Indah",
      "RS Siloam Kebon Jeruk",
      "Jakarta Eye Center",
    ],
    poin2: [
      "Tunas Metropolitan School",
      "IPEKA Puri Christian School",
      "Global Sevilla School",
      "Al-Azhar Kembangan",
    ],
    poin3: [
      "Puri Indah Mall",
      "Lippo Mall Putri",
      "Taman Anggrek Mall",
      "Central Park Mall",
    ],
  };
  return (
    <section id="surrounding" className="section-surrounding">
      <div className="container-surrounding">
        <h2 className="title">Surrounding Area</h2>
        <div className="container-image">
          <Slider {...settings}>
            {surroundingImages.map((item, index) => (
              <img
                src={item}
                alt="Area Sekitar South Tresor"
                className="image"
              />
            ))}
          </Slider>
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconHospital className="icon-fasilitas" />
              <span>Public Facilities</span>
            </div>
            {pointData.poin1.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconEducation className="icon-fasilitas" />
              <span>Education</span>
            </div>
            {pointData.poin2.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconShopping className="icon-fasilitas" />
              <span>Shopping Centers</span>
            </div>
            {pointData.poin3.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-loc"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Surrounding;
