import l7 from "./L7/L7.webp";
import l8 from "./L8/L8.webp";
import l81 from "./L8/L8.jpg";
import l82 from "./L8/L8 (1).jpg";
import l83 from "./L8/L8 (2).jpg";
import l84 from "./L8/L8 (3).jpg";
import l85 from "./L8/L8 (4).jpg";
import l86 from "./L8/L8 (5).jpg";
import l87 from "./L8/L8 (6).jpg";
import l88 from "./L8/L8 (7).jpg";
import l9 from "./L9/L9.webp";

const l7Images = [l7, l7];
const l8Images = [l8, l81, l82, l83, l84, l85, l86, l87, l88];
const l9Images = [l9, l9];

export { l7Images, l8Images, l9Images };
