import "./About.scss";
import aboutImg from "../../Assets/About (1).webp";
import { ReactComponent as Checkin } from "../../Assets/svg/checkin.svg";

const About = () => {
  const pointData = [
    "Desain Mewah dan Modern",
    "Lokasi Strategis",
    "Penawaran Menarik",
    "Pengembang Terpercaya",
    "Fasilitas Lengkap",
  ];
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282332091818&text=Halo+Anthony,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Perumahan%20South%20Tresor)%20https://southtresormetland.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section className="section-about" id="about">
      <div className="container-about">
        <div className="caption">South Tresor</div>
        <h2 className="title">ENCIRCLED BY SOPHISTICATION</h2>
        <p className="description">
          South Tresor adalah perwujudan dari hunian modern dan mewah di jantung
          Barat Jakarta. Kami memahami bahwa rumah adalah investasi masa depan,
          dan kami berkomitmen untuk menyediakan hunian yang memenuhi kebutuhan
          dan gaya hidup Anda. Dengan lokasi strategis yang hanya satu menit
          dari akses tol Jakarta-Tangerang, South Tresor menawarkan kemudahan
          dan kenyamanan yang tiada duanya.
        </p>
        <div className="container-hook">
          <div className="container-image">
            <img src={aboutImg} alt="about" />
          </div>
          <div className="container-checkin">
            {pointData.map((point, index) => (
              <div className="checkin" key={index}>
                <Checkin width={19} height={19} fill="#609C9B" />
                <span>{point}</span>
              </div>
            ))}
            <button onClick={waOpen} className="btn-navbar">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
