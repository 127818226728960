import "./Promo.scss";
import flyer from "../../Assets/flyer (1).webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Whatsapp } from "../../Assets/svg/whatsapp.svg";
const Promo = () => {
  const pointData = {
    poin1: ["FREE PPN 11%*", "CLUBHOUSE DALAM CLUSTER", "3 MENIT KE AKSES TOL"],
    poin2: [
      "BONUS AC TIAP KAMAR",
      "VOUCHER FURNISH 50 JUTA*",
      "GRANDPRIZE IONIQ 5",
    ],
  };
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282332091818&text=Halo+Anthony,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Promo%20Keseluruhan%20South%20Tresor)%20https://southtresormetland.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section id="promo" className="section-promo">
      <div className="container">
        <div className="flyer">
          <img src={flyer} alt="promo flyer" />
        </div>
        <div className="highlights">
          <h4>PROMO KESELURUHAN SOUTH TRESOR</h4>
          <div className="highlights-bottom">
            <div className="msg-hook">
              <div className="container-point">
                {pointData.poin1.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="container-point">
                {pointData.poin2.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>

              <div className="container-btn">
                <div className="term">*T&C apply</div>
                <button onClick={waOpen} className="btn">
                  <Whatsapp className="icon" />
                  CONTACT US
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promo;
